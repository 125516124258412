import React from "react"
import cx from "classnames"
import styles from "./ResearchForm.module.scss"
import { Checkbox, Input } from "components/form"
import { Button } from "components/button"
import { Column, Row } from "../../grid"

export const ResearchForm = (props) => {
  const { formState, register, onSubmit, errors } = props

  if (formState.success) {
    return (
      <div className={styles.boxSuccess}>
        <Row>
          <Column sm={12} className={styles.successContainer}>
            <h2 className={cx(styles.boxFormTitle, "serif")}>
              Thank you for your information!
            </h2>
            <p className={cx(styles.infoText, "h5")}>
              We need to confirm your email address. To become a Martian, please
              click on the link in the email we’ve just sent you.
            </p>
          </Column>
        </Row>
      </div>
    )
  }

  return (
    <div className={styles.box}>
      <Row>
        <Column sm={12} md={6} className={styles.textWrapper}>
          <h2 className={cx(styles.boxFormTitle, "serif")}>Get this report</h2>
          <p className={styles.boxFormCopy}>
            Fill out the form and we’ll deliver it to your inbox.
          </p>
        </Column>

        <Column sm={12} md={6}>
          <form onSubmit={onSubmit} className={styles.boxForm}>
            <Input name="check" value="" type="hidden" innerRef={register} />
            <div className={cx(styles.boxFormField, styles.boxFormFieldName)}>
              <Input
                name="name"
                type="text"
                placeholder="Your name"
                innerRef={register({
                  required: "Please fill in this field.",
                })}
                error={errors.name && errors.name.message}
              />
            </div>
            <div className={cx(styles.boxFormField, styles.boxFormFieldEmail)}>
              <Input
                name="email"
                type="text"
                placeholder="Your email"
                innerRef={register({
                  required: "Please fill in this field.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
                    message: "Invalid email address.",
                  },
                })}
                error={errors.email && errors.email.message}
              />
            </div>
            <div className={styles.boxFormField}>
              <Checkbox
                id="subscribe"
                name="subscribe"
                type="checkbox"
                label={<>Subscribe to Martian Mantra</>}
                innerRef={register({
                  required: "This option is mandatory.",
                })}
                error={errors.subscribe && errors.subscribe.message}
              />
            </div>

            <p className={cx(styles.info, "small")}>
              By subscribing to Martian Mantra I agree to the Martian & Machine{" "}
              <a
                href="/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>

            <div className={cx(styles.boxFormField)}>
              <Button
                variant="primary"
                type="submit"
                className={styles.boxFormSubmit}
                disabled={formState.submitting}
              >
                {formState.submitting ? "Submitting..." : "Get the research"}
              </Button>
            </div>
            {formState.success === false && (
              <p className={cx(styles.error, "small")}>{formState.message}</p>
            )}
          </form>
        </Column>
      </Row>
    </div>
  )
}
