import React, { Component } from "react"
import { withCookies } from "react-cookie"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { HeroInner } from "components/mantra/hero/HeroInner"
import { Section } from "components/section"
import { ArticleFull } from "components/mantra/article-full"
import { RelatedArticles } from "components/mantra/related-articles"
import { NewsletterPopup } from "components/mantra/newsletter-popup"
import NewsletterFooter from "../components/mantra/newsletter-footer/NewsletterFooter"
import ArticleJoinUs from "../components/mantra/article-join-us/ArticleJoinUs"
import { articleTypeInsights } from "../types/ArticleType"

class MantraInner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      readArticles: [],
      popupVisible: false,
    }
  }

  hidePopup = () => {
    this.setState({ popupVisible: false })
    this.props.cookies.set("hidePopup", true)
  }

  markArticleAsRead = (slug) => {
    const cookieHidePopup = this.props.cookies.get("hidePopup")
    let newReadArticles = []

    if (cookieHidePopup) return

    if (!this.state.readArticles.includes(slug)) {
      newReadArticles = [...this.state.readArticles, slug]
      this.props.cookies.set("readArticles", newReadArticles, { path: "/" })

      this.setState({
        readArticles: newReadArticles,
      })
    }

    if (newReadArticles.length >= 2 && !this.state.popupVisible) {
      this.setState({
        popupVisible: true,
      })
    }
  }

  componentDidMount = () => {
    const cookieReadArticles = this.props.cookies.get("readArticles")
    const cookieHidePopup = this.props.cookies.get("hidePopup")

    if (cookieHidePopup) {
      this.setState({
        popupVisible: false,
      })
    }

    if (cookieReadArticles) {
      this.setState({ readArticles: cookieReadArticles })
    }

    window?.dataLayer?.push({
      event: "view-mantra-article",
      authorName: this.props.pageContext.article.author.name,
    })
  }

  getNewsletterImageUrl = (article) => {
    const url = new URL("https:" + article?.headerImage?.fixed?.src)
    return `${url.origin}${url.pathname}?w=200&q=100`
  }

  render() {
    const { pageContext, location } = this.props
    const { popupVisible } = this.state
    const { article } = pageContext
    const isResearch = article.type === "Research"
    const excerpt = article.excerpt?.excerpt
    const description = article.description || excerpt
    const showJoinUsSection =
      article.showJoinUsSection !== null ? article.showJoinUsSection : true
    const newsletterImageUrl = this.getNewsletterImageUrl(article)

    return (
      <Layout
        location={location}
        isMantra={true}
        isResearch={isResearch}
        title={article.title}
      >
        <Meta
          title={article.title}
          description={description}
          headerImage={article.headerImage}
          seoDescription={article.seoDescription}
          seoTitle={article.seoTitle}
          noIndex={article.noIndex}
        />
        <HeroInner
          type={article.type}
          tags={article.tags}
          title={article.title}
          author={article.author}
          date={article.date}
          readingTime={article.body.fields.readingTime}
          headerImage={article.headerImage}
        />
        <Section noTopSpacing noBottomSpacing>
          <ArticleFull
            location={location}
            type={article.type}
            title={article.title}
            slug={article.slug}
            body={article.body.json}
            headerImage={article.headerImage}
            quote={article.quote}
            attachment={article.attachment}
            attachmentUrl={article.attachmentUrl}
            newsletterImageUrl={newsletterImageUrl}
            markArticleAsRead={this.markArticleAsRead}
            excerpt={excerpt}
          />
        </Section>
        <RelatedArticles articles={article.relatedArticles} />
        {article.type === articleTypeInsights && showJoinUsSection && (
          <Section noTopSpacing>
            <ArticleJoinUs />
          </Section>
        )}
        <NewsletterFooter
          isResearch={isResearch}
          researchAttachment={article.attachment}
          researchAttachmentUrl={article.attachmentUrl}
          researchName={article.title}
          newsletterImageUrl={newsletterImageUrl}
        />
        {!isResearch && (
          <NewsletterPopup visible={popupVisible} hide={this.hidePopup} />
        )}
      </Layout>
    )
  }
}

export default withCookies(MantraInner)
