import React from "react"
import cx from "classnames"
import s from "./RelatedArticles.module.scss"
import { Section } from "components/section"
import { Row } from "components/grid"
import { ArticleBox } from "components/mantra/article-box"
import { articleTypeThoughts } from "../../../types/ArticleType"
import { Column } from "../../grid"

export const RelatedArticles = (props) => {
  const { articles } = props

  if (!articles || (Array.isArray(articles) && articles.length === 0)) {
    return null
  }

  const mappedArticles = articles.map((article) => {
    const sysId = article?.sys?.contentType?.sys?.id
    const type = sysId === "thought" ? articleTypeThoughts : article.type

    return {
      ...article,
      type,
    }
  })

  let filteredArticles = mappedArticles;
  if (process.env.GATSBY_ENVIRONMENT === "production") {
    filteredArticles = mappedArticles.filter((article) => !article.preview);
  }

  return (
    <Section noTopSpacing padding={"small"}>
      <h2 className={cx(s.title, "serif")}>Related mantras</h2>
      <Row>
        {filteredArticles.map((article, i) => (
          <Column className={s.column} sm={12} md={4} key={article.id}>
            <ArticleBox
              related={true}
              author={article.author}
              key={article.id}
              index={i}
              type={article.type}
              tags={article.tags}
              title={article.title}
              slug={article.slug}
              image={article.headerImage}
              excerpt={article.excerpt && article.excerpt.excerpt}
              bodyText={article.bodyText}
              date={article.date}
              readingTime={article.body?.fields?.readingTime}
              facebookLink={article.facebookLink}
              instagramLink={article.instagramLink}
              linkedInLink={article.linkedInLink}
              fullHeight={true}
            />
          </Column>
        ))}
      </Row>
    </Section>
  )
}
