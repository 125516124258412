import React, { useState } from "react"
import cx from "classnames"
import { motion } from "framer-motion"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

import styles from "./Share.module.scss"
import { IconShare, IconClose } from "./assets"
import { IconFacebook, IconTwitter, IconLinkedin } from "./assets"
import { useShareContext } from "./ShareContext"
import { useBreakpoint } from "../../../hooks/useBreakpoint"

const variants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 2,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      staggerChildren: 2,
    },
  },
}

export const Share = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { isVisible } = useShareContext()
  const shareUrl = props.location.href
  const breakpoint = useBreakpoint()
  const alwaysVisible = breakpoint === "sm"

  return (
    <div className={cx(styles.holder, props.className)}>
      <div
        className={cx(styles.wrapper, {
          [styles.isHidden]: !alwaysVisible && !isVisible,
        })}
      >
        <button
          className={styles.share}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <IconClose /> : <IconShare />}
        </button>
        <motion.div
          animate={isExpanded ? "visible" : "hidden"}
          initial="hidden"
          variants={variants}
          className={styles.popover}
        >
          <FacebookShareButton
            url={shareUrl}
            resetButtonStyle={false}
            className={cx(styles.button, styles.facebook)}
            id="social-share-facebook"
          >
            <IconFacebook />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            resetButtonStyle={false}
            className={cx(styles.button, styles.twitter)}
            id="social-share-twitter"
          >
            <IconTwitter />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            resetButtonStyle={false}
            className={cx(styles.button, styles.linkedin)}
            id="social-share-linkedin"
          >
            <IconLinkedin />
          </LinkedinShareButton>
        </motion.div>
      </div>
    </div>
  )
}
