import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { options } from "./options"
import { Share } from "components/mantra/share"
import { ArticleProgress } from "components/mantra/article-progress"
import { Holder } from "../../holder"
import cx from "classnames"

import styles from "./ArticleFull.module.scss"
import { ShareContextProvider } from "../share/ShareContext"
import ShareVisibilityController from "../share/ShareVisibilityController"
import { ResearchFormContainer } from "../research-form/ResearchFormContainer"

export class ArticleFull extends Component {
  constructor(props) {
    super(props)

    this.content = React.createRef()
  }

  render() {
    const {
      location,
      type,
      title,
      slug,
      body,
      attachment,
      attachmentUrl,
      markArticleAsRead,
      excerpt,
      newsletterImageUrl,
    } = this.props

    const isResearch = type === "Research"

    return (
      <ShareContextProvider defaultVisible={!isResearch}>
        <div className={styles.wrapper}>
          <ArticleProgress
            title={title}
            slug={slug}
            contentRef={this.content}
            markArticleAsRead={markArticleAsRead}
          />
          <div className={styles.mainContent}>
            <div ref={this.content}>
              <Share
                className={cx(styles.share, {
                  [styles.isResearch]: isResearch,
                })}
                location={location}
              />
              {excerpt && !isResearch && (
                <Holder className={styles.holderWrapper}>
                  <div className={styles.excerptWrapper}>
                    <h4 className={cx(styles.excerpt, "serif")}>{excerpt}</h4>
                  </div>
                </Holder>
              )}

              <div className={styles.content}>
                {isResearch && (
                  <ShareVisibilityController hideBefore={true}>
                    <div className={styles.researchBox}>
                      <ResearchFormContainer
                        researchAttachment={attachment}
                        researchAttachmentUrl={attachmentUrl}
                        researchName={title}
                        newsletterImageUrl={newsletterImageUrl}
                      />
                    </div>
                  </ShareVisibilityController>
                )}
                <div className={styles.contentWrapper}>
                  {documentToReactComponents(body, options)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShareContextProvider>
    )
  }
}
