import styles from "./QuestionAndAnswerBlock.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React from "react"
import { options } from "../article-full/options"
import PropTypes from "prop-types"

const QuestionAndAnswerBlock = ({ question, answer }) => {
  return (
    <>
      <div className={styles.question}>
        <h3 className={styles.title}>{question}</h3>
      </div>
      <div className={styles.answer}>
        {documentToReactComponents(answer, options)}
      </div>
    </>
  )
}

QuestionAndAnswerBlock.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.object.isRequired,
}

QuestionAndAnswerBlock.defaultProps = {}

export default QuestionAndAnswerBlock
