import React from "react"
import cx from "classnames"
import { Holder } from "components/holder/Holder"
import { MetaHead } from "components/mantra/meta/MetaHead"
import { MetaFoot } from "components/mantra/meta/MetaFoot"

import Img from "gatsby-image"

import styles from "./Hero.module.scss"

export const HeroInner = (props) => {
  const hasImage = !!props.headerImage

  return (
    <div>
      <div className={styles.imagePositioner}>
        <div
          className={cx(styles.imageWrapper, {
            [styles.imageWrapperNoImage]: !hasImage,
          })}
        >
          {hasImage && (
            <Img
              fixed={props.headerImage.fixed}
              alt={props.headerImage.title}
              className={styles.image}
            />
          )}
        </div>
      </div>

      <div className={cx(styles.wrapper)}>
        <Holder className={styles.holder}>
          <div
            className={cx(styles.contentWrapper, {
              [styles.noOffset]: !hasImage,
            })}
          >
            <MetaHead type={props.type} tags={props.tags} />
            <h1 className={styles.title}>{props.title}</h1>
            <MetaFoot
              type={props.type}
              author={props.author}
              readingTime={props.readingTime}
              date={props.date}
            />
          </div>
        </Holder>
      </div>
    </div>
  )
}
