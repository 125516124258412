import React from "react"
import styles from "./Code.module.scss"
import Highlight from "react-highlight"

export const Code = props => {
  const { children } = props

  return (
    <div className={styles.code}>
      <Highlight>{children}</Highlight>
    </div>
  )
}
