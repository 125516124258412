import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { options } from "../mantra/article-full/options"
import styles from "./Callout.module.scss"

const Callout = ({ className, content }) => {
  return (
    <div className={cn(className, styles.callout)}>
      {documentToReactComponents(content, options)}
    </div>
  )
}

Callout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Callout.defaultProps = {
  className: "",
  children: undefined,
}

export default Callout
