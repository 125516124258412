import React, { useEffect, useState } from "react"
import { Controller, Scene } from "react-scrollmagic"
import { useMeasure } from "react-use"
import { useShareContext } from "./ShareContext"
import { useBreakpoint } from "../../../hooks/useBreakpoint"

const shareHeight = 48

const SceneHolder = React.forwardRef(
  ({ children, setCurrentEvent, event }, ref) => {
    useEffect(() => {
      setCurrentEvent(event)
    }, [event, setCurrentEvent])

    return <div ref={ref}>{children}</div>
  }
)

const ShareVisibilityController = ({
  children,
  hideBefore,
  hideAfter,
  className,
}) => {
  const [currentEvent, setCurrentEvent] = useState(undefined)
  const [ref, { height }] = useMeasure()
  const { setIsVisible, defaultVisible } = useShareContext()
  const breakpoint = useBreakpoint()
  const isEnabled = breakpoint !== "sm"

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const eventState = currentEvent?.state

    if (eventState === undefined && defaultVisible !== undefined) {
      setIsVisible(defaultVisible)
      return
    }

    if (hideBefore && eventState === "BEFORE") {
      setIsVisible(false)
      return
    }

    if (hideAfter && eventState === "AFTER") {
      setIsVisible(false)
      return
    }

    const isVisible = eventState !== "DURING"
    setIsVisible(isVisible)
  }, [
    currentEvent,
    setIsVisible,
    isEnabled,
    hideBefore,
    hideAfter,
    defaultVisible,
  ])

  return (
    <div ref={ref} className={className}>
      <Controller>
        <Scene
          enabled={isEnabled}
          duration={height + shareHeight * 2}
          indicators={false}
          offset={-shareHeight}
          triggerHook={0.15}
        >
          {(progress, event) => {
            return (
              <SceneHolder setCurrentEvent={setCurrentEvent} event={event}>
                {children}
              </SceneHolder>
            )
          }}
        </Scene>
      </Controller>
    </div>
  )
}

export default ShareVisibilityController
