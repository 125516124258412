import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import cx from "classnames"
import s from "./NewsletterPopup.module.scss"
import { Input } from "components/form/Form"
import { Button } from "components/button"
import iconLetter from "../../../assets/icons/letter.svg"
import iconClose from "./assets/close.svg"
import iconCheckmark from "./assets/checkmark.svg"

const Wrap = ({ children }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.wrapperInner}>{children}</div>
    </div>
  )
}

export const NewsletterPopup = (props) => {
  const { register, handleSubmit, errors } = useForm()

  const [popupState, setPopupState] = useState({
    intro: true,
  })

  const [formState, setFormState] = useState({
    submitting: false,
    success: null,
    message: null,
  })

  const handleServerResponse = (success, message) => {
    setFormState({
      submitting: false,
      success: success,
      message: message,
    })
  }

  const onSubmit = (values) => {
    if (values.check !== "") {
      // honeypot
      return
    }

    setFormState({ submitting: true })

    axios({
      method: "POST",
      url: process.env.GATSBY_NEWSLETTER_CONFIRM,
      data: {
        email: values.email,
      },
    })
      .then((r) => {
        handleServerResponse(true)
      })
      .catch((r) => {
        handleServerResponse(false, "Error")
      })
  }

  if (props.visible && formState.success) {
    return (
      <Wrap wrapperWithCookieBox={!props.hasMartianCookies}>
        {formState && formState.success && (
          <div className={s.content}>
            <button className={s.buttonClose} onClick={() => props.hide()}>
              <img src={iconClose} alt="Close" />
            </button>
            <div className={s.icon}>
              <img src={iconCheckmark} alt="Checkmark" />
            </div>
            <h3 className={cx("serif h4", s.title)}>Thank you</h3>
            <p className={s.paragraph}>
              Confirm your address on the email we’ve sent you and receive news
              and new mantras from Mars on a monthly basis.
            </p>
          </div>
        )}
      </Wrap>
    )
  } else if (props.visible && !formState.success) {
    return (
      <Wrap wrapperWithCookieBox={!props.hasMartianCookies}>
        {popupState.intro ? (
          <div className={s.content}>
            <button className={s.buttonClose} onClick={() => props.hide()}>
              <img src={iconClose} alt="Close" />
            </button>
            <div className={s.icon}>
              <img src={iconLetter} alt="E-mail" />
            </div>
            <h3 className={cx("serif h4", s.title)}>Hey Mantra Insider!</h3>
            <p>Stay in the loop by subscribing to our Mantra Newsletter.</p>
            <div className={cx(s.formField, s.formFieldButton)}>
              <Button
                variant="primary"
                onClick={() =>
                  setPopupState({
                    intro: false,
                  })
                }
              >
                Join the club
              </Button>
            </div>
          </div>
        ) : (
          <div className={s.content}>
            <button className={s.buttonClose} onClick={() => props.hide()}>
              <img src={iconClose} alt="Close" />
            </button>
            <div className={s.icon}>
              <img src={iconLetter} alt="E-mail" />
            </div>
            <h3 className={cx("serif h4", s.title)}>
              Join our Mantra Newsletter
            </h3>
            <p>Receive news and new mantras from Mars on a monthly basis.</p>
            <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
              <Input name="check" value="" type="hidden" innerRef={register} />
              <div className={cx(s.formField)}>
                <Input
                  name="email"
                  type="text"
                  placeholder="Your email"
                  innerRef={register({
                    required: "Please fill in this field.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
                      message: "Invalid email address.",
                    },
                  })}
                  error={errors.email && errors.email.message}
                />
              </div>
              <p className={cx(s.info, "small")}>
                By subscribing to Martian Mantra I agree to the Martian &
                Machine{" "}
                <a
                  href="/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <div className={cx(s.formField, s.formFieldButton)}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={formState.submitting}
                >
                  {formState.submitting ? "Submitting..." : "Join the club"}
                </Button>
              </div>
            </form>
          </div>
        )}
      </Wrap>
    )
  } else {
    return null
  }
}
