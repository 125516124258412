import React, { useMemo } from "react"
import styles from "./ArticleJoinUs.module.scss"
import { ButtonLink } from "../../button"
import { useBreakpoint } from "../../../hooks/useBreakpoint"

const ArticleJoinUs = () => {
  const breakpoint = useBreakpoint()
  const cta = useMemo(() => {
    if (breakpoint === "xs" || breakpoint === "md") {
      return "See openings"
    }
    return "See our current openings"
  }, [breakpoint])

  return (
    <section className={styles.wrapper}>
      <h2 className={"serif"}>Join us!</h2>
      <div className={styles.content}>
        <p className={styles.text}>
          Like what you’ve read? Check out our open positions and join the
          Martian team!
        </p>
        <ButtonLink
          variant="primary"
          to={"/careers/#openings"}
          className={styles.button}
        >
          {cta}
        </ButtonLink>
      </div>
    </section>
  )
}

ArticleJoinUs.propTypes = {}

ArticleJoinUs.defaultProps = {}

export default ArticleJoinUs
