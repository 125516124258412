import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { ResearchForm } from "./ResearchForm"
import axios from "axios"

export const ResearchFormContainer = (props) => {
  const {
    researchAttachment,
    researchName,
    researchAttachmentUrl,
    newsletterImageUrl,
  } = props
  const { register, handleSubmit, errors, reset } = useForm()

  const [formState, setFormState] = useState({
    submitting: false,
    success: null,
    message: null,
  })

  const attachmentUrl = researchAttachmentUrl
    ? researchAttachmentUrl
    : researchAttachment?.file?.url

  const handleServerResponse = (success, message) => {
    setFormState({
      submitting: false,
      success: success,
      message: message,
    })
  }

  const onSubmit = (values) => {
    if (values.check !== "") {
      // honeypot
      return
    }

    setFormState({ submitting: true })

    if (values.subscribe) {
      axios({
        method: "POST",
        url: process.env.GATSBY_NEWSLETTER_CONFIRM,
        data: {
          name: values.name,
          email: values.email,
          attachmentUrl: attachmentUrl,
          researchName: researchName,
          newsletterImageUrl,
        },
      })
        .then((r) => {
          handleServerResponse(true)
          reset()
        })
        .catch((r) => {
          handleServerResponse(
            false,
            "Error submitting the form. Please try again."
          )
        })
    }
  }

  return (
    <ResearchForm
      formState={formState}
      register={register}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
    />
  )
}
