import React, { useState } from "react"

export const ShareContext = React.createContext(undefined)

export const ShareContextProvider = ({ children, defaultVisible = true }) => {
  const [isVisible, setIsVisible] = useState(defaultVisible)

  return (
    <ShareContext.Provider
      value={{
        isVisible,
        setIsVisible,
        defaultVisible,
      }}
    >
      {children}
    </ShareContext.Provider>
  )
}

export const useShareContext = () => React.useContext(ShareContext)
