import React from "react"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import styles from "./ArticleFull.module.scss"
import {
  ListItem,
  OrderedList,
  UnorderedList,
} from "components/typography/list"
import { Quote } from "components/typography/quote"
import { Code } from "components/typography/code"
import { RichTextImage } from "../rich-text/RichTextImage"
import QuestionAndAnswerBlock from "../question-and-answer-block/QuestionAndAnswerBlock"
import Callout from "../../callout/Callout"

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children, next) => {
      const marks = node.content[0].marks

      if (marks && marks.length > 0) {
        if (marks[0].type === "code") {
          return <Code>{node.content[0].value}</Code>
        }
      }

      // if (
      //   node.content.length === 1 &&
      //   find(node.content[0].marks, { type: "code" })
      // ) {
      //   console.log("Match")
      //   return <Code>${node.content[0].value}</Code>
      // }

      return <p className={styles.p}>{children}</p>
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className={styles.h1}>{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className={styles.h2}>{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className={styles.h3}>{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className={styles.h4}>{children}</h4>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.CODE]: (node, children) => <Code>{children}</Code>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.QUOTE]: (node, children) => {
      return <Quote>{documentToPlainTextString(node)}</Quote>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, description, file } = node.data.target.fields
      const mimeType = file["en-US"].contentType
      const mimeGroup = mimeType.split("/")[0]

      switch (mimeGroup) {
        case "image":
          return (
            <RichTextImage
              title={title ? title["en-US"] : null}
              description={description ? description["en-US"] : null}
              file={file}
            />
          )
        case "application":
          return (
            <a
              alt={description ? description["en-US"] : null}
              href={file["en-US"].url}
            >
              {title ? title["en-US"] : file["en-US"].details.fileName}
            </a>
          )
        default:
          return (
            <span style={{ backgroundColor: "red", color: "white" }}>
              {mimeType} embedded asset{" "}
            </span>
          )
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      return `<img class='custom-asset' src="${node.data.target.fields.file["en-US"].url}"/>`
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node?.data?.target?.sys?.contentType?.sys?.id === "callout") {
        return (
          <Callout content={node?.data?.target?.fields?.content["en-US"]} />
        )
      }

      if (
        node?.data?.target?.sys?.contentType?.sys?.id ===
        "questionAndAnswerBlock"
      ) {
        return (
          <QuestionAndAnswerBlock
            question={node?.data?.target?.fields?.question["en-US"]}
            answer={node?.data?.target?.fields?.answer["en-US"]}
          />
        )
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      if (
        node?.data?.target?.sys?.contentType?.sys?.id ===
        "questionAndAnswerBlock"
      ) {
        return (
          <QuestionAndAnswerBlock
            question={node?.data?.target?.fields?.question["en-US"]}
            answer={node?.data?.target?.fields?.answer["en-US"]}
          />
        )
      }

      return `<div class='custom-entry' />${node.data.target.fields.name["en-US"]}</div>`
    },
  },
}
