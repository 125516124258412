import React, { Component } from "react"
import cx from "classnames"
import styles from "./ArticleProgress.module.scss"

let scrolled = false

export class ArticleProgress extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      readingPercentage: 0,
    }

    this.wrapper = React.createRef()
  }

  checkScroll = (e) => {
    scrolled = true
  }

  getReadingPercentage = () => {
    const scrollTop = window.pageYOffset || document.body.scrollTop
    const bodyRect = document.body.getBoundingClientRect()
    const contentRef = this.props.contentRef.current
    const contentRect = contentRef.getBoundingClientRect()
    const contentOffset = contentRect.top - bodyRect.top

    let readingPercentage = (
      ((scrollTop - contentOffset) /
        (contentRect.height - window.innerHeight)) *
      100
    ).toFixed(2)

    if (readingPercentage < 0) {
      readingPercentage = 0
    } else if (readingPercentage > 100) {
      readingPercentage = 100
      this.props.markArticleAsRead(this.props.slug)
    }

    return readingPercentage
  }

  isArticleBodyVisible = () => {
    const contentRef = this.props.contentRef.current
    const rect = contentRef.getBoundingClientRect()
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight

    return rect.top <= windowHeight && rect.top + rect.height >= 0
  }

  handleScroll = () => {
    if (!scrolled) return
    scrolled = false

    const scrollTop = window.pageYOffset || document.body.scrollTop

    const bodyRect = document.body.getBoundingClientRect()

    const contentRef = this.props.contentRef.current
    const contentRect = contentRef.getBoundingClientRect()

    const contentOffset = contentRect.top - bodyRect.top

    const shouldShow = this.isArticleBodyVisible() && scrollTop > contentOffset

    requestAnimationFrame(() => {
      if (shouldShow) {
        this.setState({
          visible: true,
          readingPercentage: this.getReadingPercentage(),
        })
      } else {
        this.setState({ visible: false })
      }
    })
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.checkScroll)
    this.scrollInterval = setInterval(this.handleScroll, 50)
  }

  componentWillUnmount = () => {
    scrolled = false
    clearInterval(this.scrollInterval)
    window.removeEventListener("scroll", this.checkScroll)
  }

  render() {
    const { title } = this.props
    const { visible } = this.state

    return (
      <div
        ref={this.wrapper}
        className={cx(styles.wrapper, {
          [styles.visible]: visible,
        })}
      >
        <div className={styles.container}>{title && <h6>{title}</h6>}</div>
        <progress
          className={styles.progress}
          value={this.state.readingPercentage}
          max="100"
        ></progress>
      </div>
    )
  }
}
