import React from "react"
import styles from "./RichTextImage.module.scss"
import Zoom from "react-medium-image-zoom"
import ShareVisibilityController from "../share/ShareVisibilityController"
import PropTypes from "prop-types"
import useContentfulImage from "../../../hooks/useContentfulImage"
import Image from "gatsby-image"

export const RichTextImage = (props) => {
  const fluid = useContentfulImage(props.file["en-US"].url)

  const image = props.file["en-US"].details.image
  const width = image?.width

  return (
    <ShareVisibilityController className={props.className}>
      <div className={styles.imageWrapper}>
        <Zoom>
          <Image
            className={styles.image}
            title={props.title}
            alt={props.description}
            fluid={fluid}
            imgStyle={{
              width,
              height: "auto",
              objectFit: "contain",
            }}
            placeholderClassName={styles.placeholder}
          />
        </Zoom>
        {props.description && (
          <div className={styles.caption}>
            <p>{props.description}</p>
          </div>
        )}
      </div>
    </ShareVisibilityController>
  )
}

RichTextImage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  node: PropTypes.object,
}
